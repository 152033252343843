import React, { useEffect, useState } from "react";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import SecHead from "../SectionHeader/SecHead";
import { useDispatch, useSelector } from "react-redux";
import { getPublicLocalNews } from "../../redux/actions/publicAction";
import moment from "moment";
import { Link } from "react-router-dom";
import loader from "../../assets/loader.gif";

const LocalNewsHomeComp = () => {
  const dispatch = useDispatch();

  const { publicLocalNews } = useSelector((state) => state.publicReducer);
  const [loading, setLoading] = useState(true);

  // const {topStoriesData}=useSelector((state)=>state.privateReducer);

  const isVerified = localStorage.getItem("accessToken");

  useEffect(() => {
    // if (!isVerified) {
    dispatch(getPublicLocalNews(3)).then(() => {
      setLoading(false);
    });
    // } else {
    //   // dispatch(topStories())
    // }
  }, []);

  // console.log("publicLocalNews",publicLocalNews);

  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }
  return (
    <div className="bg-white w-full p-3 md:p-5 px-4 rounded-lg">
      <SecHead title={"Local News"} redirectLink="/local-news" />

      {!loading ? (
        <div className=" ">
          {Array.isArray(publicLocalNews) &&
            publicLocalNews.map((item, index) => (
              <Link
                // to={`/local-news-details/${item?.uuid}`}
                // to={`/local-news/${item?.slug}`}
                to={isVerified ? `/local-news/${item?.slug}` : "/login"}
              >
                <div
                  key={item?.id}
                  className={`flex flex-row items-start  gap-4 md:gap-4 py-2 border-b border-gray-300   ${
                    index === publicLocalNews.length - 1
                      ? "border-none"
                      : "border-b"
                  } justify-between`}
                >
                  <div className="flex flex-col mt-2 md:mt-0 md:pt-1 md:gap-2 md:opacity-90">
                    <CategoryPolls
                      title={item?.category?.name || "country"}
                      id={item?.category?.id}
                      img={item?.category?.icon}
                    />
                    <span
                      className=" text-[16px] text-gray-800 leading-[18px] font-semibold tracking-normal "
                      style={{ lineHeight: 1.4 }}
                    >
                      {item?.title}
                    </span>

                    <span className="date_shown mt-4">
                      {" "}
                      {moment(item[0]?.createdAt).format("MMMM D, YYYY")}
                    </span>
                  </div>

                  <img
                    src={item?.file ?? ""}
                    alt={item?.fileId}
                    className="  w-20 mt-2 md:w-20 h-auto object-cover rounded-md "
                  />
                </div>
              </Link>
            ))}
        </div>
      ) : (
        <>
          <div className="min-h-[80vh] bg-white">
            <div className="flex flex-col h-[70vh] justify-center items-center">
              <div className="object-contain ">
                <img src={loader} alt="loading..." />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LocalNewsHomeComp;
