import { useEffect, useState } from "react";
import shareicon from "../../assets/icons/Path 23100.svg";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import leftPredictionPerson from "../../assets/images/Kamala harris pic.png";
import rightPredictionPerson from "../../assets/images/Donald trump pic.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getElectoralVotes,
  getPollsAverage,
} from "../../redux/actions/pollingAction";

function UsePollPredictionComp({ pollType = "popular" }) {
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (pollType === "popular") {
      dispatch(getPollsAverage());
    } else {
      dispatch(getElectoralVotes());
    }
  }, [pollType]);

  const { pollsAverageResponse, electoralVotesResponse } = useSelector(
    (state) => state?.pollWeb
  );

  const totalVotes = 538;
  console.log("electoralVotesResponse ", electoralVotesResponse);

  const handleChange = (event, newValue) => {
    console.log("newValue", newValue);

    setValue(newValue);
  };
  return (
    <>
      {/* prediction  */}

      <div className="flex flex-row justify-center gap-9 py-10 my-10">
        <img
          src={leftPredictionPerson}
          alt="Kamala Harris"
          className="w-24 h-24"
        />
        <span className="flex flex-col text-center justify-center">VS</span>
        <img
          src={rightPredictionPerson}
          alt="Donald Trump"
          className="w-24 h-24"
        />
      </div>

      <div className="flex flex-row justify-center">
        <Box
          sx={{
            width: 600,
            height: 20,
            position: "relative",
            backgroundColor: "gray",
            borderRadius: "0",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
          className={`bg-gradient-to-r from-indigo-700 via-gray-200  to-red-600 `}
        >
          <div
            className="text-3xl text-indigo-600"
            style={{ position: "absolute", left: 0, bottom: 30 }}
          >
            {/* {value < 0 ? Math.abs(value) : 0}{" "} */}
            {pollType === "popular"
              ? Math.round(
                  (totalVotes * pollsAverageResponse?.Dem_Average) / 100
                )
              : electoralVotesResponse?.votesForHarris}
          </div>
          {/* Right Label */}
          <div
            className="text-3xl text-red-500 "
            style={{ position: "absolute", right: 0, bottom: 30 }}
          >
            {/* {value > 0 ? value : 0}{" "} */}
            {pollType === "popular"
              ? Math.round(
                  (totalVotes * pollsAverageResponse?.GOP_Average) / 100
                )
              : electoralVotesResponse?.votesForTrump}
          </div>
          <Slider
            aria-label="Custom Slider"
            value={value}
            onChange={handleChange}
            // className="bg-red-500"
            valueLabelDisplay="off"
            step={1}
            min={-100}
            max={100}
            sx={{
              "& .MuiSlider-thumb": {
                width: 2,
                height: 30,
                // backgroundColor: 'red',
                border: "1px solid #000",

                borderRadius: "0",
              },
              "& .MuiSlider-track": {
                backgroundColor: "#fff",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#fff",
              },
            }}
          />
          {/* Left Label */}

          <div
            style={{
              position: "absolute",
              left: 0,
              top: 30,
              justifyContent: "start",
              textAlign: "start",
            }}
          >
            <div className="">Kamala Harris</div>
            <div className="text-gray-500 text-xs">
              {/* {value < 0 ? Math.abs(value) + "% of Vote" : 0} */}
              {pollType === "popular"
                ? pollsAverageResponse?.Dem_Average + "% of Votes"
                : ""}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: 50,
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
            }}
            className="text-gray-500 text-xs"
          >
            {pollType === "popular" ? "50%" : "270 to win"}
          </div>
          {/* Right Label */}
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 30,
              justifyContent: "end",
              textAlign: "end",
            }}
          >
            <div className="">Donald Trump</div>
            <div className="text-gray-500 text-xs">
              {" "}
              {/* {value > 0 ? value + "% of Vote" : 0} */}
              {pollType === "popular"
                ? pollsAverageResponse?.GOP_Average + "% of Votes"
                : ""}
            </div>
          </div>
        </Box>
      </div>

      {/* prediction end */}
    </>
  );
}
export default UsePollPredictionComp;
