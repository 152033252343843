import { useParams } from "react-router-dom";
import UsePollPredictionComp from "../../components/UsePollPredictionComp/UsePollPredictionComp";
import StateComp from "../../components/StateComp/StateComp";
import VoteShare from "../../components/VoteShare/VoteShare";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import shareicon from "../../assets/icons/Path 23100.svg";
import flagImg from "../../assets/flag (2).png";

function UsePollPrediction() {
  const { id } = useParams();
  // console.log("UsePollPrediction", id);
  return (
    <>
      <div className="bg_home px-4 md:px-40 flex flex-col pb-14">
        <div className="flex-[2]">
          <div className="flex flex-col md:flex-row gap-5 py-5">
            <div className="flex-[2] flex flex-col gap-5">
              <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5  md:block">
                <div className=" h-auto pb-14 ">
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between">
                      <div className="">
                        {" "}
                        <CategoryPolls title={"US Polls"} img={flagImg} />
                      </div>
                      <div className=" border rounded-md p-2">
                        {" "}
                        <img src={shareicon} alt="share" className="h-4" />
                      </div>
                    </div>
                  </div>
                  <UsePollPredictionComp />
                </div>
                <VoteShare />
              </div>
            </div>
            <div className="flex-1 flex flex-col gap-5">
              <StateComp />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UsePollPrediction;
