import { Link, useLocation } from "react-router-dom";

function FAQ() {
  const location = useLocation();
  window.scrollTo(0, 0);

  // console.log("location",location.pathname);

  const isVerified = localStorage.getItem("accessToken");
  const breadCumb = [
    { id: 1, name: isVerified ? "For You " : "News ", link: "/" },
    {
      id: 2,
      name: "FAQ",
      link: `${location?.pathname}`,
    },
  ];
  return (
    <>
      <div className="bg_home md:bg-[#F7F8FA]  px-0 min-[840px]:px-40 flex flex-col min-[840px]:pb-14 md:py-0">
        <div className="flex flex-row items-center py-5">
          {breadCumb.map((item, index) => (
            // console.log("item",item),

            <span
              key={item.id}
              className={`text-[10px] font-medium  ${
                index === breadCumb.length - 1 ? "" : "opacity-60"
              }`}
            >
              {index > 0 && <span className="mx-1">&gt;</span>}
              <Link to={item?.link} className="breadcrumb-link">
                {item.name}
              </Link>
            </span>
          ))}
        </div>
        <div className="md:bg-[#FFFFFF] w-full  md:round-lg ">
          <div className="bg-white w-full  p-3 md:p-5 md:round-lg h-[10rem]">
            {/* <SecHead title={"Top Stories"} redirectLink="/top-stories" /> */}
            <h3 className=" font-sans text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] hidden md:block">
              FAQ
            </h3>
            <div className=" mt-2">
              <p>FAQ Coming Soon...</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FAQ;
