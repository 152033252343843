// import PaginationComponents from "components/PaginationComponents";
import React, { useState } from "react";
function DynamicTable({ column, data, isPagination, borderleft }) {
  // const [currentPageData, setCurrentPageData] = useState(data);
  return (
    <div className="table-responsive px-3">
      <table className="min-w-full bg-white">
        <thead className="bg-[#F7F8FA] ">
          <tr className="text-[12px] border-l-2">
            {column.map((ele, index) => (
              // console.log("ele",ele),

              <th
                key={index}
                className="min-h-24 border-b border-transparent text-center border-l-2  font-semibold text-gray-700 min-w-40 p-2"
              >
                <p className={`mb-0 `}>{ele.title}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length ? (
            data.map((rowValue, idxRow) => (
              <tr
                key={rowValue?.id}
                className="border-b  border-gray-200 text-[12px]"
              >
                {column.map((key, idxHead) => {
                  if (key?.customField) {
                    if (key.field === "toggle") {
                      return (
                        <td key={idxHead} className="px-2 py-2">
                          <input
                            type="checkbox"
                            checked={rowValue[key.field]}
                            // onChange={(e) => handleToggle(e.target.checked, rowValue.id)} // Uncomment if needed
                          />
                        </td>
                      );
                    }
                    // return <td key={idxHead}>{key.customField(rowValue, idxRow)}</td>;
                    return (
                      <td
                        key={idxHead}
                        className={`p-2 font-[500] text-center text-gray-600 ${
                          key?.borderleft ? "border-l-2" : ""
                        }`}
                      >
                        {key.customField(rowValue, idxRow)}
                      </td>
                    );
                  }
                  return null; // Return null for undefined fields
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={column.length} className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* {isPagination && 
      
        <PaginationComponents pagedata={data} setCurrentPageData={setCurrentPageData} currentPageData={currentPageData}/>
      } */}
    </div>
  );
}

export default DynamicTable;
